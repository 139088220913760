/* You can add global styles to this file, and also import other style files */

//== Vendor
@import '../node_modules/font-awesome/css/font-awesome.css';
@import '../node_modules/simple-line-icons/css/simple-line-icons.css';

@import '../node_modules/spinkit/css/spinkit.css';
@import '../node_modules/loaders.css/loaders.css';

// @import '../node_modules/ng2-dnd/style.css';

@import '../node_modules/ag-grid/dist/styles/ag-grid.css';
@import '../node_modules/ag-grid/dist/styles/theme-fresh.css';

@import '../node_modules/jqcloud2/dist/jqcloud.css';

@import '../node_modules/summernote/dist/summernote.css';

@import '../node_modules/fullcalendar/dist/fullcalendar.min.css';

@import '../node_modules/codemirror/lib/codemirror.css';

// @import '../node_modules/sweetalert/dist/sweetalert.css';

//== Bootstrap
@import "./app/shared/styles/bootstrap.scss";
//== Application
@import "./app/shared/styles/app.scss";

@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

@import "~ng-pick-datetime/assets/style/picker.min.css";

// custom css
.mat-header-cell {
  font-weight: 800 !important;
  font-size: 14px !important;
}
.mat-cell {
  padding: 3px !important;
}
.mat-sort-header-arrow {
  opacity: 1 !important;
}
.mat-form-field-required-marker { color: $brand-purple; }
.mandatory-hint {
  margin-right: 50%;
  font-size: 13px;
  color: $brand-purple;
}
.icon-purple {
  width: 80px;
  height: 80px;
}